import React from "react"
import styled from "styled-components"
import { breakpoints } from "../utils"
import { IArticleTopicForPageContext } from "api/types"
import { navigate } from "gatsby"

export interface TopicProps {
  readonly id?: string
  readonly link?: string
  readonly className?: string
  readonly active?: boolean
  readonly onClick?: Function
  readonly iconStyles?: any
}

const Container: any = styled.div`
  width: 130px;
  margin: 5px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.tabletLandscape}px) {
    margin-right: 8px;
    padding-top: 20px;
    width: 100px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    width: 120px;
    margin: 0px;
  }
`
const IconWrapper = styled.a`
  text-decoration: none !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 100%;
  border: 1px solid lightgrey;
  width: 80px;
  height: 80px;
  cursor: pointer;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
`
const Label = styled.a`
  color: ${props => props.theme.colors.secondary};
  font-size: 19px;
  text-decoration: none !important;
  text-align: center;
  max-width: 180px;
  margin-top: 12px;
  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

const Topic: React.FC<
  Partial<IArticleTopicForPageContext> & TopicProps
> = props => {
  let Icon
  try {
    Icon = require(`../images/${props.icon}`)
    console.log(props.icon)
    // console.log("Icon", Icon)
  } catch (err) {
    console.error("Could not find icon:", props.icon)
  }

  if (!Icon) {
    return null
  }
  const iconStyles = { width: "48px", stroke: "", fill: "" }
  const iconWrapperStyles: any = {}
  if (props.active) {
    iconStyles.stroke = "white"
    iconStyles.fill = "white"
    iconWrapperStyles.background = "#EC4D7B"
  } else {
    iconStyles.stroke = "#EC4D7B"
    iconStyles.fill = "#EC4D7B"
  }

  return (
    <Container>
      <IconWrapper
        className="icon-wrapper hover:shadow"
        style={iconWrapperStyles}
        onClick={() => navigate(props?.link as string)}
      >
        <Icon className="icon" style={iconStyles} />
      </IconWrapper>
      {props.title && (
        <Label
          className="label"
          onClick={() => navigate(props?.link as string)}
        >
          {props.title}
        </Label>
      )}
    </Container>
  )
}

export default Topic
